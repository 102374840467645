import { Box, Button, Card, CardMedia, Grid, Typography, Stack } from "@mui/material";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import './videoeditor.scss';
import { Header } from "../../component/home-header/header";
import { Footer } from "../../component/home-footer/footer";
import Carousel from 'react-material-ui-carousel'

import { GetProjectLink } from "../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const VideoEditor = () => {
  
  const handleCreateNewProject = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
  }

  const VideooEditor1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-Signup.jpeg'
  const VideooEditor2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-Pricing.jpeg'
  const VideooEditor3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-EditorPage.jpeg'
  const VideooEditor4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-Templates.jpeg'
  const VideooEditor5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-Subtitles.jpeg'
  const VideooEditor6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-TextToSpeech.jpeg'
  const VideooEditor7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AIImage.jpeg'
  const VideooEditor8 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
  const VideooEditor9 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
  const VideooEditor10 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
  const VideooEditor11 = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
        <Box sx={{ paddingY: '10px'}}>
          <Carousel>
            <Box sx={{display:'flex', justifyContent:'center', alignItems: 'center', padding: '2rem', height: '500px'}}>
              <Box className="div-1232" >
                <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography variant="h2" fontWeight={700} gutterBottom >Online Video Editor</Typography>
                    </Box>
                    <Box sx={{paddingBottom: '1rem'}}>
                      <Typography variant="body1" gutterBottom >
                      You can become a professional video editor with "www.videoo.io" in no time
                      </Typography>
                    </Box>
                    <Box onClick={handleCreateNewProject}>
                      <Button variant="contained" className="create-now" color="success">Create Now</Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} height={'100%'}>
                    <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}> 
                      <iframe
                        src="https://www.youtube.com/embed/sStiFfdEz6E"
                        title="Videoo.io, Online Cloud Based Video Editor"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      ></iframe>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems: 'center', padding: '2rem', height: '500px'}}>
              <Box className="div-1232" >
                <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography variant="h2" fontWeight={700} gutterBottom>Online Video Editor</Typography>
                    </Box>
                    <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom >
                      Video Editing, Made Easy for You.
                    </Typography>
                    </Box>
                    <Box onClick={handleCreateNewProject}>
                      <Button variant="contained" className="create-now" color="success">Create Now</Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} height={'100%'}>
                    <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}> 
                      <iframe
                        src="https://www.youtube.com/embed/mawUZrfSY_E"
                        title="Videoo.io, Online Cloud Based Video Editor"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      ></iframe>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems: 'center', padding: '2rem', height: '500px'}}>
              <Box className="div-1232" >
                <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography variant="h2" fontWeight={700} gutterBottom >Online Video Editor</Typography>
                    </Box>
                    <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom >
                      You can add Subtitles, Translations, AI-Visuals to your Videos in a single click
                    </Typography>
                    </Box>
                    <Box onClick={handleCreateNewProject}>
                      <Button variant="contained" className="create-now" color="success">Create Now</Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} height={'100%'}>
                    <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}> 
                      <iframe
                        src="https://www.youtube.com/embed/8dgRwW6dLWQ"
                        title="Videoo.io, Online Cloud Based Video Editor"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      ></iframe>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Carousel>
        </Box>
        {/* <Box className="section-15" sx={{marginTop: '4rem'}}> 
          <Box className="div-1232">
            <Typography variant="h3" gutterBottom fontWeight={'bold'}>
            Add Images to Your <br />Videos Online
            </Typography>
          </Box>
          <Box className="div-1232" sx={{marginBottom: '1rem'}}>
            <Typography variant="body1" gutterBottom >
            Lorem ipsum dolor sit amet.
            </Typography>
          </Box>
          <Box className="div-1232" onClick={handleCreateNewProject}>
            <Button variant="contained" className="create-now" color="success">Create Now</Button>
          </Box>
        </Box> */}
        <Box className='section-15 pt-12'>
          <Box className='div-1232'>
            <Box className='breadcrumbs'>
              <a href={'/'}>
                <Box className='breadcrumb-text-block'>Videoo</Box>
              </a>
              <Box className='breadcrumb-arrow'></Box>
              <a href={'/'}>
                <Box className='breadcrumb-text-block'>Video Editor</Box>
              </a>
              <Box className='breadcrumb-arrow'></Box>
            </Box>
          </Box>
        </Box>

        <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
          <Box className="div-1232">
            <Box className="text-center">
            <Typography className="mx-auto" variant="h4" gutterBottom fontWeight={600} >How to edit a video with online video editor</Typography>
            </Box>
            <Box className="text-center" sx={{marginBottom: '3rem'}}>
              <Typography variant="body1" gutterBottom >Videoo.io is your new best friend for video editing.</Typography>
            </Box>
            <Box className="container">
              <Grid container spacing={15}>
                <Grid item md={4} xs={12} className="text-center">
                  <Card className="p-6">
                    <Box className="text-center py-4">
                      <img className="mx-auto" src={VideooEditor1}/>
                    </Box>
                    <Box className="text-center py-4">
                      <Typography variant="h5" gutterBottom fontWeight={600} >Step 1</Typography>
                    </Box>
                    <Box className="text-center py-4">
                    <Typography variant="body1" gutterBottom >Signup & Login to "www.videoo.io". Then you can create a blank project or select a Template or Stock Content</Typography>
                    </Box>
                  </Card>
                </Grid>
                <Grid item md={4} xs={12} className="text-center">
                  <Card className="p-6">
                    <Box className="text-center py-4">
                      <img className="mx-auto" src={VideooEditor2}/>
                    </Box>
                    <Box className="text-center py-4">
                      <Typography variant="h5" gutterBottom fontWeight={600} >Step 2</Typography>
                    </Box>
                    <Box className="text-center py-4">
                    <Typography variant="body1" gutterBottom >You can subscribe to Videoo.io. This will give you exclusive access to AI Subtitles, Translations and AI-Visuals. Your exports will not include a watermark anymore.</Typography>
                    </Box>
                  </Card>
                </Grid>
                <Grid item md={4} xs={12} className="text-center">
                  <Card className="p-6">
                    <Box className="text-center py-4">
                      <img className="mx-auto" src={VideooEditor3}/>
                    </Box>
                    <Box className="text-center py-4">
                      <Typography variant="h5" gutterBottom fontWeight={600} >Step 3</Typography>
                    </Box>
                    <Box className="text-center py-4">
                    <Typography variant="body1" gutterBottom >Now, you can easily use our AI Video Editor, all functionality included! Collaborate with your friends and your imagination will be reflected in your videos.</Typography>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
          <Box className="div-1232">
            <Box className="mx-auto text-center" sx={{width: '80%'}}>
              <Typography variant="h3" gutterBottom fontWeight={600} >Features of Videoo.io</Typography>
            </Box>
            <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
              <Typography variant="body1" gutterBottom >All tools ... With a single click !</Typography>
            </Box>
            <Box>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                <Grid item md={6} xs={12} >
                  <img width={'100%'} src={VideooEditor4} />
                </Grid>
                <Grid item md={6} xs={12} >
                  <Box sx={{paddingBottom: '0.5rem'}}>
                    <Typography variant="h4" gutterBottom fontWeight={600} >Tailor Made Templates</Typography>
                  </Box>
                  <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom>
                      Tailor-Made Templates in VIDEOO
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      VIDEOO offers tailor-made templates to simplify the video editing process for social media creators. Key features include:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      1. <strong>Platform-Specific Designs:</strong> Optimized for Instagram, TikTok, YouTube, and Facebook.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      2. <strong>Customizable Elements:</strong> Easily adjust colors, fonts, and text to match your brand.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      3. <strong>Pre-Set Animations and Effects:</strong> Apply professional animations with a single click.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      4. <strong>Industry-Specific Templates:</strong> Options for beauty, fashion, travel, food, fitness, and more.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      5. <strong>AI-Enhanced Customization:</strong> AI suggests optimal color schemes, fonts, and layouts.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      6. <strong>Regular Updates:</strong> New templates added regularly to keep up with trends.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      These templates help users create high-quality, engaging videos quickly and easily.
                    </Typography>
                  </Box>
                  <Box sx={{paddingTop: '1rem'}}>
                    <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                <Grid item md={6} xs={12} >
                  <img width={'100%'} src={VideooEditor5} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{paddingBottom: '0.5rem'}}>
                    <Typography variant="h4" gutterBottom fontWeight={600} >Translate Subtitles and Audio</Typography>
                  </Box>
                  <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom>
                      Translate Subtitles and Audio in VIDEOO
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      VIDEOO offers powerful translation features for subtitles and audio, making your content accessible globally.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      1. <strong>AI-Generated Subtitle Translations:</strong> Translate subtitles into multiple languages with a single click.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      2. <strong>AI-Generated Audio Translations:</strong> Generate natural-sounding voices in different languages.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      3. <strong>Multi-Language Dual Subtitles:</strong> Add subtitles in two languages simultaneously.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      4. <strong>Seamless Integration:</strong> Easily translate and edit subtitles and audio within the workflow.
                    </Typography>
                  </Box>
                  <Box sx={{paddingTop: '1rem'}}>
                    <Button to="../add-subtitles-to-video" component={Link} variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                <Grid item md={6} xs={12} >
                  <img width={'100%'} src={VideooEditor6} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{paddingBottom: '0.5rem'}}>
                    <Typography variant="h4" gutterBottom fontWeight={600} >AI Voices (Text-to-Speech)</Typography>
                  </Box>
                  <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom>
                      AI Voices (Text-to-Speech) and AI Images
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      VIDEOO uses advanced AI to enhance your videos with high-quality voices and images.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      1. <strong>AI Voices (TTS):</strong> Generate professional, human-like voices for your videos.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      2. <strong>Powered by Voiser.net & Google:</strong> Videoo.io supports 50+ languages, with 250+ different voices.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      3. <strong>Voice Options:</strong> Change pitch, speed and volume to suit your video.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      4. <strong>Seamless Integration:</strong> Easily add AI-generated voices which are indistinguisable from real human voices.
                    </Typography>
                  </Box>
                  <Box sx={{paddingTop: '1rem'}}>
                    <Button to="../ai-voice-generator" component={Link} variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                <Grid item md={6} xs={12} >
                  <img width={'100%'} src={VideooEditor7} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{paddingBottom: '0.5rem'}}>
                    <Typography variant="h4" gutterBottom fontWeight={600} >AI Generated Images</Typography>
                  </Box>
                  <Box sx={{paddingBottom: '1rem'}}>
                    <Typography variant="body1" gutterBottom>
                      AI-Generated Visuals with VIDEOO
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      VIDEOO uses AI to create stunning visual content effortlessly.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      1. <strong>Automated Image Creation:</strong> Generate high-quality images with a click.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      2. <strong>Customizable Visuals:</strong> Easily match visuals to your brand's style.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      3. <strong>Diverse Styles:</strong> Select from various themes to suit your video’s tone.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      4. <strong>Enhanced Creativity:</strong> AI suggests new visual ideas and concepts.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      5. <strong>Time-Saving:</strong> Focus more on content, less on design.
                    </Typography>
                  </Box>
                  <Box sx={{paddingTop: '1rem'}}>
                    <Button to="../ai-image-generator" component={Link} variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        
        
        <Box className="section-9 directory" sx={{paddingY: '3rem'}}>
          <Box className="div-1232">
            <Box>
              <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Other Video Tools</Typography>
            </Box>
            <Box className="text-center">
              <Typography className="text-center" variant="body1" gutterBottom >There are plenty of other features that are avaliable with Videoo.io</Typography>
            </Box>
          </Box>
          <Box >
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <Card className="p-6" sx={{background: '#abbed133'}}>
                  <Grid>
                    <img width={'100%'} src={VideooEditor8} />
                  </Grid>
                  <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                    Add Image to Video
                  </Typography>
                  <Box className="mx-auto text-center">
                    <Button to="../add-image-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={3} xs={12}>
                <Card className="p-6" sx={{background: '#abbed133'}}>
                  <Grid >
                    <img width={'100%'} src={VideooEditor9} />
                  </Grid>
                  <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                    Add Music to Video
                  </Typography>
                  <Box className="mx-auto text-center">
                    <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={3} xs={12}>
                <Card className="p-6" sx={{background: '#abbed133'}}>
                  <Grid >
                    <img width={'100%'} src={VideooEditor10} />
                  </Grid>
                  <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                  Add Text to Video
                  </Typography>
                  <Box className="mx-auto text-center">
                    <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={3} xs={12}>
                <Card className="p-6" sx={{background: '#abbed133'}}>
                  <Grid >
                    <img width={'100%'} src={VideooEditor11} />
                  </Grid>
                  <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                  Add Free Stock Content to Video
                  </Typography>
                  <Box className="mx-auto text-center">
                    <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="section-9 directory" sx={{paddingY: '3rem'}}>
          <Box className="div-1232 px-18">
            <Box className="text-center">
              <Typography variant="h4" gutterBottom fontWeight={600} >Frequently Asked Questions About Video Editing</Typography>
            </Box>
            <Box className="text-center mx-auto" sx={{marginBottom: '3rem', width: '60%'}}>
              <Typography variant="body1" gutterBottom >
              Welcome to our FAQ section on video editing! Here, we address the most common questions and provide helpful insights to enhance your video editing skills. Whether you're a beginner or an experienced editor, you'll find valuable tips and best practices to create stunning videos.
              </Typography>
            </Box>
            <Box className="py-15">
              <Typography variant="h5" gutterBottom fontWeight={600} >Question #1</Typography>
              <Typography variant="h5" gutterBottom > What are the best practices for editing videos for social media?</Typography>
              <Typography variant="body1" gutterBottom >  Answer #1: When editing videos for social media, consider the platform's preferred video dimensions, keep the content concise and engaging, and use captions to ensure accessibility. Additionally, leverage trending music and effects to enhance viewer engagement.</Typography>
            </Box>
            <Box className="py-15">
              <Typography variant="h5" gutterBottom fontWeight={600} >Question #2</Typography>
              <Typography variant="h5" gutterBottom > How can I improve the quality of my video edits?</Typography>
              <Typography variant="body1" gutterBottom >  Answer #2: To improve video quality, use high-resolution footage, ensure proper lighting, and utilize video stabilization tools. Color correction and grading can also significantly enhance the visual appeal of your videos.</Typography>
            </Box>
            <Box className="py-15">
              <Typography variant="h5" gutterBottom fontWeight={600} >Question #3</Typography>
              <Typography variant="h5" gutterBottom > What software should beginners use for video editing?</Typography>
              <Typography variant="body1" gutterBottom >  Answer #3: You should consider user-friendly software like Videoo.io, iMovie, Capcut, Kapwing, InVideo, Veed or Adobe Premiere Pro. These platforms offer intuitive interfaces and essential features that are easy to learn and use.</Typography>
            </Box>
            <Box className="py-15">
              <Typography variant="h5" gutterBottom fontWeight={600} >Question #4</Typography>
              <Typography variant="h5" gutterBottom > How can AI tools help in video editing?</Typography>
              <Typography variant="body1" gutterBottom >  Answer #4: AI tools can automate repetitive tasks like creating subtitles, translating audio, generating visuals, and applying effects. This not only saves time but also enhances the overall quality and creativity of the video.</Typography>
            </Box>
            <Box className="py-15">
              <Typography variant="h5" gutterBottom fontWeight={600} >Question #5</Typography>
              <Typography variant="h5" gutterBottom > What are the benefits of collaborative video editing?</Typography>
              <Typography variant="body1" gutterBottom >  Answer #5: Collaborative video editing allows multiple users to work on the same project simultaneously, increasing productivity and fostering creative input. It also streamlines the feedback process and ensures a cohesive final product.</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
          <Box className="section-15 py-12 mx-auto">
            <Box className="div-1232">
              <Box className="mx-auto" >
                <Typography sx={{width: '80%', textAlign: 'center', marginX: 'auto'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Social Media Videos Easily</Typography>
              </Box>
              <Box className="mx-auto text-center" sx={{marginTop: '2rem'}} onClick={handleCreateNewProject}>
                <Button to="../add-free-stock-to-video" variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="footer-containner"><Footer /></Box>
    </Box>
  )
}