import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { CutIcon, RecordIcon, LiveIcon, HomeIcon, GridBoxIcon, BrandKitIcon, BetaBadgeIcon, LightiningIcon, BusinessChecke2 } from './../../Icons/index';
import userImage from "../../assets/image/user.png"

export const copyObject = (obJSON) => {
  let tempJSON = JSON.parse(JSON.stringify(obJSON));
  return tempJSON;
}

export const CalcuDifferentDay = (day) => {
  let day1 = new Date();
  let day2 = new Date(day);
  let dif_day = day1.getTime() - day2.getTime();
  let TotalYears = Math.floor(dif_day / (1000 * 3600 * 24 * 30 * 12));
  let TotalMonths = Math.floor(dif_day / (1000 * 3600 * 24 * 30));
  let TotalDays = Math.floor(dif_day / (1000 * 3600 * 24));
  let Totalhours = Math.floor(dif_day / (1000 * 3600));
  let Totalmins = Math.floor(dif_day / (1000 * 60));
  let Totalsecs = Math.floor(dif_day / (1000));
  return TotalYears > 0? TotalYears + ' years ago':TotalMonths > 0? TotalMonths + ' months ago' : TotalDays > 0 ? TotalDays+' days ago' : Totalhours > 0 ? Totalhours+' hour ago' : Totalmins > 0 ? Totalmins + ' mins ago' : Totalsecs + ' sec ago' ;
  // return TotalDays ? TotalDays : '0';
}

export const PlanMenuInfo = [
  // {
  //   icon: <SettingsSuggestOutlinedIcon />,
  //   name: 'Workspace Settings',
  //   link: '#'
  // }, {
  //   icon: <PeopleOutlinedIcon />,
  //   name: 'Collaborators',
  //   link: '#'
  // }, {
  //   icon: <HttpsOutlinedIcon />,
  //   name: 'Privacy & SSO',
  //   link: '#'
  // },
  {
    icon: <ArticleOutlinedIcon />,
    name: 'Billing',
    link: '#'
  },

]

export const NewVideoInfo = [
  {
    icon: <LightiningIcon />,
    iconclass: 'create-icon',
    title: 'Create New Project',
    badge: { state: false },
    link: '',
  },
  // {
  //   icon: <RecordIcon />,
  //   iconclass: 'record-icon',
  //   title: 'Start Recording',
  //   link: ''
  // }, {
  //   icon: <LiveIcon />,
  //   iconclass: 'live-icon',
  //   title: 'Go Live',
  //   link: ''
  // }
]

export const SidebarMenuInfo = [
  // {
  //   icon: <HomeIcon />,
  //   title: 'Home',
  //   link: '/workspaces/projects',
  //   badge: { state: false },
  //   class: ''
  // },
  {
    icon: <CutIcon />,
    title: 'Projects',
    link: 'projects',
    badge: { state: false },
    class: ''
  },
  {
    icon: <ArticleOutlinedIcon />,
    title: 'Stock Content',
    link: 'stocks',
    badge: { state: false },
    class: ''
  },
  {
    icon: <GridBoxIcon />,
    title: 'Templates',
    link: 'templates',
    badge: { state: false },
    class: ''
  },
  {
    icon: <BrandKitIcon />,
    title: 'Custom Templates',
    link: 'custometemplates',
    badge: { state: false },
    class: ''
  },
  {
    icon: <FolderOutlinedIcon />,
    title: 'Exports',
    link: 'exports',
    badge: { state: false },
    class: ''
  },
  {
    icon: <RecordIcon />,
    title: 'Assets',
    link: 'assets',
    badge: { state: false },
    class: ''
  },
  {
    icon: <HomeIcon />,
    title: 'Compress',
    link: 'compresss',
    badge: { state: false },
    class: ''
  },
  {
    icon: <CheckOutlinedIcon />,
    title: 'Transcodes',
    link: 'transcodes',
    badge: { state: false },
    class: ''
  },
  // {
  //   icon: <FolderOutlinedIcon />,
  //   title: 'All Videos',
  //   link: 'all-videos',
  //   badge: { state: false },
  //   class: ''
  // },
  {
    icon: <Diversity2Icon className='opacity-80'/>,
    title: 'WorkSpace',
    link: 'workspace',
    badge: { state: false },
    class: ''
  },

  {
    icon: <AccountCircleOutlinedIcon />,
    title: 'Profile',
    link: 'profile',
    badge: { state: false },
    class: ''
  },
  // {
  //   icon: <BetaBadgeIcon className='opacity-80'/>,
  //   title: 'API keys',
  //   link: 'apikeys',
  //   badge: { state: false },
  //   class: ''
  // },
  {
    icon: <PaymentIcon />,
    title: "Payment Options",
    link: "payment",
    badge: { state: false },
    class: ''
  },
  // {
  //   icon: <SettingsSuggestOutlinedIcon />,
  //   title: "Preferences",
  //   link: "preferences",
  //   badge: { state: false },
  //   class: ''
  // }

  // {
  //   icon: <RecordIcon />,
  //   title: 'Recordings',
  //   link: 'recordings',
  //   badge: { state: false },
  //   class: ''
  // }, {
  //   icon: <LiveIcon />,
  //   title: 'Live Stream',
  //   link: 'live-stream',
  //   badge: {
  //     state: true,
  //     class: '',
  //     icon: <BetaBadgeIcon />
  //   },
  //   class: ''
  // }, {
  //   icon: <BrandKitIcon />,
  //   title: 'Brand Kit',
  //   link: 'brand-kit',
  //   badge: {
  //     state: true,
  //     class: 'brand-kit-badge',
  //     icon: <LightiningIcon />
  //   },
  //   class: ''
  // }
]

export const TemplatesHeaderItems = [
  { title: 'All', link: 'all' },
  { title: 'Featured', link: 'featured' },
  { title: 'Audio', link: 'audio' },
  { title: 'Corporate', link: 'corporate' },
  { title: 'Events', link: 'events' },
  { title: 'Learning', link: 'learning' },
  { title: 'Marketing', link: 'marketing' },
  { title: 'Media', link: 'media' }
]

export const membershipPlan = {
  0: 'Free',
  1: 'Basic',
  2: 'Premium',
  3: 'Business',
  4: 'Basic_Yearly',
  5: 'Premium_Yearly',
  6: 'Business_Yearly'
}